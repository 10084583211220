<template>
  <div class="list-item" :class="{ active: this.item.legacy_journal }">
    <i class="fa fa-check-square mr-3 activate" @click="activateJournal"></i>
    <Buttons :item="item" :index="index" v-if="this.item.legacy_journal" />
    {{ item.stripped_title }}
  </div>
</template>
<script>
import SortableListButtons from "./SortableListButtons";

export default {
  components: {
    Buttons: SortableListButtons,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    drag: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    activateJournal() {
      this.item.legacy_journal = !this.item.legacy_journal;
      this.$store.dispatch("addToLegacyJournal", [
        this.item.ID,
        this.item.legacy_journal ? "1" : "0",
        ({ message }) => {
          this.$toast.open({
            message,
            position: "bottom-left",
          });
        },
      ]);
    },
  },
  mounted() {
    // console.log(this.item);
  },
};
</script>
<style scoped>
.list-item {
  opacity: 0.6;
}
.activate {
  cursor: pointer;
  color: #3e3e3e;
}
.activate:hover {
  color: #000;
}
.list-item.active {
  opacity: 1;
}
.list-item.active .activate {
  color: #28a745;
}
</style>