<template>
  <div class="btn-group btn-group-sm float-right" role="group">
    <button
      type="button"
      class="btn btn-sm btn-secondary btn-move-up"
      @click="moveUp"
      :class="{ noEditButton: !showEditButton }"
    >
      <i class="fa fa-caret-up" aria-hidden="true"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-secondary btn-move-down"
      @click="moveDown"
    >
      <i class="fa fa-caret-down" aria-hidden="true"></i>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-success"
      @click="triggerModal"
      v-if="showEditButton"
    >
      Edit
    </button>
  </div>
</template>

<script>
import PageEditorModal from "./PageEditorModal";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      showModal: false,
      showEditButton: true,
    };
  },
  computed: {
    ...mapGetters(["getLegacyJournal"]),
    items: {
      get() {
        return this.getLegacyJournal;
      },
      set(value) {
        this.$store.commit("SET_LEGACY_JOURNAL", value);
      },
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    moveUp() {
      const new_index = this.index - 1 < 0 ? 0 : this.index - 1;
      this.moveItem(this.index, new_index);
      this.updatePosition();
    },
    moveDown() {
      const new_index = this.index + 1;
      this.moveItem(this.index, new_index);
      this.updatePosition();
    },
    moveItem(from, to) {
      const items = this.getLegacyJournal;
      var f = items.splice(from, 1)[0];
      items.splice(to, 0, f);
      this.items = items;
    },
    updatePosition() {
      this.$store.dispatch(
        "updateLegacyJournalPosition",
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        }
      );
    },
    triggerModal() {
      this.$modal.show(
        PageEditorModal,
        {
          item: this.item,
          closeTo: "nothing",
        },
        {
          name: "sortable-list-modal-" + this.item.ID,
          resizable: true,
          minWidth: 880,
          minHeight: 605,
        }
      );
    },
  },
  mounted() {
    //console.log( this.item );
  },
};
</script>
<style scoped>
.legacy-journal-container
  .list-group
  .list-group-item:last-child
  .btn-move-up.noEditButton {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}
</style>