<template>
  <div>
    <spinner v-if="loading" />
    <SortableListAllItems v-else />
  </div>
</template>

<script>
import SortableListAllItems from "./SortableListAllItems.vue";
import Spinner from "./Spinner.vue";
export default {
  components: {
    SortableListAllItems,
    Spinner,
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    this.$store.dispatch("loadLegacyJournal", [
      "1",
      () => {
        this.$store.dispatch("loadDefaults", [
          [
            "default_font_family",
            "default_font_size",
            "image_upload_limit",
            "image_upload_count",
            "template_folder",
          ],
          () => {
            this.loading = false;
          },
        ]);
      },
    ]);
  },
};
</script>
<style>
.handle {
  cursor: all-scroll;
}
</style>