<template>
  <div v-tooltip.top_left="message">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: "You may drag & drop.",
    },
  },
};
</script>
<style>
.tooltip {
  opacity: 0.5;
}
</style>