<template>
  <div>
    <spinner v-if="items.length == 0" />
    <draggable
      v-if="items.length > 0"
      class="list-group"
      v-model="items"
      group="items"
      @start="drag = true"
      @end="drag = false"
      @change="updatePosition"
      handle=".handle"
    >
      <Tooltip
        message="You may drag and drop..."
        :drag="drag"
        class="list-group-item"
        v-for="(item, index) in items"
        :index="index"
        :key="item.ID"
      >
        <SortableListItem :item="item" :index="index" class="handle" />
      </Tooltip>
    </draggable>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import SortableListItem from "./SortableListItem";
import Spinner from "./Spinner.vue";
import Tooltip from "./Tooltip";
export default {
  props: {
    allItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    draggable,
    SortableListItem,
    Tooltip,
    Spinner,
  },
  data() {
    return {
      drag: false,
    };
  },
  computed: {
    ...mapGetters(["getLegacyJournal"]),
    items: {
      get() {
        return this.getLegacyJournal
          .filter(() => true)
          .sort((a, b) => a.position - b.position);
      },
      set(value) {
        value.map((item, index) => (item.position = index));
        //this.$store.commit("SET_LEGACY_JOURNAL", value);
      },
    },
  },
  methods: {
    updatePosition() {
      this.$store.dispatch("updateLegacyJournalPosition2", [
        this.getLegacyJournal,
        ({ success, data: { message } }) => {
          if (success) {
            this.$toast.open({
              message,
              position: "bottom-left",
            });
          }
        },
      ]);
    },
  },
};
</script>
<style>
.handle {
  cursor: all-scroll;
}
</style>